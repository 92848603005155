import {
  FontFamily, FontSize, TextTransformOptions, Components,
} from '@powdr/constants';

const fonts = {
  [FontFamily.BASE_FONT]: {
    fontFamily: '"Geom Slab_703 W01 Light", "Times New Roman"',
    textTransform: TextTransformOptions.NO_TRANSFORM,
    defaultSize: FontSize.REG30,
    scaling: 1,
  },
  [FontFamily.PRIMARY_FONT]: {
    fontFamily: '"Geom Slab_703 W01 Bold", "Times New Roman"',
    textTransform: TextTransformOptions.UPPERCASE,
    defaultSize: FontSize.MED40,
    scaling: 1,
  },
  [FontFamily.SECONDARY_FONT]: {
    fontFamily: '"GeomSlab703W01-Medium", "Times New Roman"',
    textTransform: TextTransformOptions.UPPERCASE,
    defaultSize: FontSize.REG30,
    scaling: 1,
  },
};

const fontOverrides = {};

export const fontTheme = {
  fonts,
  FontSize,
  fontOverrides,
};
